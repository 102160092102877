export const SANITY_API_VERSION = '2023-04-08'
export const previewSecretId = 'preview.secret'

export const base = {
	dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || 'production',
	projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || 'u5ms77ce',
	apiVersion: SANITY_API_VERSION,
}

export const cdn = {
	...base,
	useCdn: true,
}

export const auth = {
	...base,
	useCdn: false,
	token: process.env.SANITY_API_TOKEN,
}

// sanity types to next routes resolution
export const nextRoutes = {
	home: '/',
	articles: '/articles',
	about: '/about',
	page: '/*',
	article: '/article/*',
	thesaurus: '/thesaurus',
	people: '/people',
	resources: '/resources',
	aboutus: '/aboutus',
	presskit: '/presskit',

	// legals
	legal: '/legal/*',
}
