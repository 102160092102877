import {HTMLAttributes, ButtonHTMLAttributes, ReactElement, ReactNode, forwardRef} from 'react'
import Link from 'next/link'
import useResolveLink, {LinkInput} from '@local/hooks/useResolveLink'

import {clsx} from '@local/utils'

import {Spinner} from '@local/ui/components/spinner/Spinner'
import CheckSVG from '@local/assets/svgs/check.svg'

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
	children?: ReactNode,
	className?: string,
	activeClassName?: string,
	linkInput?: LinkInput,
	href?: string,
	target?: string,
	/** Use `<Link />` from `next/link` or use native `<a>`. */
	disabled?: boolean,
	/** `aria-label` for a11y. */
	label?: string,
	/** Determine's the styles on a button. */
	variant?: 'none' | 'primary' | 'secondary' | 'classicUnderlined' | 'solidUnderlined',
	theme?: 'none' | 'black' | 'white' | 'grey',
	type?: ButtonHTMLAttributes<HTMLButtonElement>['type'],
	active?: boolean,
	enableLoader?: boolean,
	loading?: boolean,
	loadingContent?: string,
	loaded?: boolean,
	loadedContent?: string,
	onClick?: any
	transparent?: boolean
	hover?: 'window' | 'underlined' | 'rotate' | 'rotateWindow' | 'solidUnderlined'
	useAnchorTag?: boolean
}

export const ButtonWithoutRef = ({
	children,
	variant = 'none',
	theme,
	linkInput,
	href,
	target,
	className,
	activeClassName,
	active = false,
	disabled,
	label,
	enableLoader = false,
	loading = false,
	loadingContent,
	loaded = false,
	loadedContent,
	onClick,
	transparent = false,
	hover,
	useAnchorTag = false,
	...props
}: ButtonProps, ref): ReactElement => {
	const resolveLink = useResolveLink((linkInput || href)!)!

	if (href) {
		href = resolveLink.href
	}

	if (resolveLink) {
		href = resolveLink.href
		label = label || resolveLink.label
		active = Boolean(active || resolveLink.active)
	}

	enableLoader = (variant === 'classicUnderlined') ? false : enableLoader

	const styles = {
		none: 'inline-block text-inherit',
		primary: clsx(
			'inline-flex items-center justify-center text-center overflow-clip',
			'uppercase text-10 lg-max:h-43 h-34 w-full px-36',
		),
		secondary: clsx(
			'inline-flex items-center justify-center text-center overflow-clip',
			'uppercase text-10 lg-max:h-43 h-34 w-full px-36',
		),
		classicUnderlined: clsx('inline-block underline'),
		solidUnderlined: clsx('inline-block'),
	}

	const colors = {
		black: clsx(
			transparent ? 'border border-black-full text-black-full' : 'bg-black-full text-white',
			disabled && 'text-white/40',
		),
		white: clsx(
			transparent ? 'border border-white text-white' : 'bg-white text-black-full',
			disabled && 'text-black-full/40',
		),
		grey: clsx(
			transparent ? 'border border-grey-light text-grey-light' : 'bg-grey-light text-black-full',
		),
	}

	const hoverBlocks = {
		window: clsx(
			'z-0 overflow-clip',
			'before:block before:absolute before:top-[calc(100%+1px)] before:-z-1 before:pointer-events-none before:inset-x-3 before:h-[calc(100%-6px)] before:rounded-[2px] hover:before:-translate-y-[calc(100%+4px)]',
			variant === 'secondary' && 'before:inset-x-0 before:h-[calc(100%+2px)] before:rounded-none hover:before:-translate-y-full',
			theme === 'black' && 'hover:text-black-full before:bg-white',
			(theme === 'white' || theme === 'grey') && 'hover:text-white before:bg-black-full',
			'before:transform-gpu before:transition-transform before:duration-700 before:ease-expo-out',
		),
		rotateWrapper: clsx(
			'relative overflow-clip',
		),
		rotateInner: clsx(
			'h-full flex gap-4 items-center justify-center',
			'transition-transform duration-1000 ease-expo-out transform-gpu',
			'group-hover/btn:-translate-y-[calc(100%+4px)]',
		),
		underlined: clsx(
			'inline-block',
			'after:absolute after:bottom-0 after:left-0',
			'after:w-full after:h-1',
			'after:bg-current',
			active ? 'after:scale-x-100 after:hover:scale-x-0 after:origin-right' : 'after:scale-x-0 after:hover:scale-x-100 after:origin-left',
			'after:transition-transform after:duration-500 after:ease-quart-out after:transform-gpu',
		),
	}

	const hovers = {
		...hoverBlocks,
		solidUnderlined: clsx(
			hoverBlocks.underlined,
			'before:absolute before:bottom-0 before:right-0',
			'before:w-full before:h-1',
			'before:bg-current',
			active ? 'before:scale-x-0 before:hover:scale-x-100 before:origin-left' : 'before:scale-x-100 before:hover:scale-x-0 before:origin-right',
			'before:transition-transform before:duration-200 before:ease-expo-out before:transform-gpu',
		),
		rotateWindow: clsx(
			hoverBlocks.window,
			hoverBlocks.rotateWrapper,
		),
	}

	const classNames = [
		'relative appearance-none cursor-pointer select-none',
		'group/btn transition-[opacity,color,background,border] duration-700 hover:duration-100 ease-cubic-out',
		variant !== 'none' && '[&>span]:transition-[transform,color] [&>span]:duration-700 [&>span]:group-hover/btn:duration-[700ms,100ms] [&>span]:inline-flex [&>span]:items-center [&>span]:justify-center [&>span]:h-full [&>span]:w-full [&>span]:pointer-events-none [&>span]:transform-gpu [&>span]:ease-quart-out',
		styles[variant],
		theme && colors[theme],
		hover && hovers[hover],
		disabled && 'pointer-events-none',
		enableLoader && loading && 'cursor-wait',
		enableLoader && loaded && 'cursor-default',
		className,
		active && activeClassName,
	]

	disabled = (enableLoader && loading) || (enableLoader && loaded)

	children = (hover && ['rotate', 'rotateWindow'].includes(hover))
		? (
			<div className={clsx(hovers.rotateWrapper)}>
				<span className={clsx(hovers.rotateInner)}>{children}</span>
				<span aria-hidden className={clsx(hovers.rotateInner, 'absolute top-[calc(100%+4px)] left-0 right-0')}>{children}</span>
			</div>
		)
		: children

	if (enableLoader) {
		children = (
			<>
				<span className={clsx(
					(loading || loaded) && '-translate-y-full',
				)}>
					{children}
				</span>
				<span className={clsx(
					'absolute left-0 top-full',
					loadingContent && 'opacity-60',
					loading && '-translate-y-full',
					loaded && '-translate-y-[200%]',
				)}>
					{loadingContent || <Spinner className='w-16 h-16' svgClass='fill-current' />}
				</span>
				<span className={clsx(
					'absolute left-0 top-full',
					loaded && '-translate-y-full',
				)}>
					{loadedContent || <CheckSVG className='w-14' />}
				</span>
			</>
		)
	}

	if (href) {
		const _target = target || href.indexOf('mailto:') === 0 || href.indexOf('http') === 0 ? '_blank' : undefined
		if (useAnchorTag) {
			return <a href={href} target={_target} className={clsx(classNames)} aria-label={label} onClick={onClick} ref={ref} {...props}>{children}</a>
		} else {
			return <Link href={href} target={_target} className={clsx(classNames)} aria-label={label} onClick={onClick} ref={ref} {...props}>{children}</Link>
		}
	} else {
		return (
			<button className={clsx(classNames)} disabled={disabled} aria-label={label} onClick={onClick} ref={ref} {...props}>
				{children}
			</button>
		)
	}
}

export const Button = forwardRef(ButtonWithoutRef)
