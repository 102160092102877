import Display from '@local/assets/svgs/logo/display.svg'

export interface LogoDisplayProps {
	parent?: string
	className?: string
}

export const LogoDisplay = ({parent, className}: LogoDisplayProps) => {
	return (
		<Display />
	)
}
