// @ts-nocheck
import {create} from 'zustand'
import Lenis from '@studio-freight/lenis'

const config = {
	// duration: 1,
	// easing: (t) => Math.min(1, 1.001 - Math.pow(2, -12 * t)), // https://www.desmos.com/calculator/brs54l4xou
	lerp: 0.0875,
	orientation: 'vertical', // vertical, horizontal
	gestureOrientation: 'vertical', // vertical, horizontal, both
	wheelMultiplier: 1,
	normalizeWheel: false,
	smoothTouch: false,
}

interface ScrollState {
	isLocked: boolean,
	setIsLocked: (value: boolean) => void,
	lenis: Lenis,
	destroyLenis: () => void,
	scrollTo: (value, options?) => void,
}

const useScrollStore = create<ScrollState>((set, get) => ({
	isLocked: false,
	setIsLocked: value => {
		const lenis = get().lenis
		const isLocked = get().isLocked

		if (value && !isLocked) {
			lenis.stop()

			set(() => ({
				isLocked: value,
			}))
		} else if (!value && isLocked) {
			lenis.start()

			set(() => ({
				isLocked: value,
			}))
		}
	},
	lenis: typeof (window) !== 'undefined' && new Lenis(config),
	scrollTo: (value, options) => get().lenis.scrollTo(value, options),
}))

export default useScrollStore
