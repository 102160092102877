import {useRef, useState} from 'react'

import {WindowEvent} from './useWindowEvent'
import useWindowResize from './useWindowResize'

export interface WindowSize {
	width: number;
	height: number;
	documentHeight: number;
}

const useWindowSize = (asRef = false, debounce = 0) => {
	const ref = useRef<WindowSize>({width: 0, height: 0, documentHeight: 0})
	const [windowSize, setWindowSize] = useState<WindowSize>({
		width: 0,
		height: 0,
		documentHeight: 0,
	})

	useWindowResize(
		(e: WindowEvent) => {
			ref.current.width = e?.innerWidth || 0
			ref.current.height = e?.innerHeight || 0
			ref.current.documentHeight = e?.documentHeight || 0

			if (!asRef) {
				setWindowSize(ref.current)
			}
		},
		true,
		[],
		debounce,
	)

	return asRef ? ref : windowSize
}

export default useWindowSize
