import {Device, DeviceEnum} from '@local/types'
import {useRef, useState} from 'react'
import useMatchDevice from './useMatchDevice'

const savedDevice: {current: Device | null} = {current: 'mobile'}

const useDevice = (asRef = false) => {
	const deviceRef = useRef<Device | null>(savedDevice.current)
	const [device, setDevice] = useState<Device | null>(savedDevice.current)

	useMatchDevice((device: Device) => {
		if (device in DeviceEnum) {
			savedDevice.current = device
			if (asRef) {
				deviceRef.current = device
			} else {
				setDevice(device)
			}
		}
	})

	if (asRef) {
		return deviceRef
	} else {
		return device
	}
}

export default useDevice
