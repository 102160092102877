import {create} from 'zustand'
// import {useStore as useScrollStore} from './useScrollStore'

interface MenuState {
	isMenuOpen: boolean,
	setIsMenuOpen: (value: boolean) => void,
	isSearchOpen: boolean,
	setIsSearchOpen: (value: boolean) => void,
	isMiniCartOpen: boolean,
	setIsMiniCartOpen: (value: boolean) => void,
}

const useMenuStore = create<MenuState>(set => ({
	isMenuOpen: false,
	setIsMenuOpen: value => set(state => {
		// useScrollStore.setState({isLocked: value || state.isSearchOpen})
		return ({isMenuOpen: value})
	}),

	isSearchOpen: false,
	setIsSearchOpen: value => set(state => {
		// useScrollStore.setState({isLocked: value || state.isMenuOpen})
		return ({isSearchOpen: value})
	}),

	isMiniCartOpen: false,
	setIsMiniCartOpen: value => set(state => {
		// useScrollStore.setState({isLocked: value || state.isMenuOpen})
		return ({isMiniCartOpen: value})
	}),
}))

export default useMenuStore
