import {nextRoutes} from '@local/config'
import rewrites from '@local/config/dist/rewrites.json'
import {defaultLocale} from '@local/config/languages.mjs'
import {NextRouter, useRouter} from 'next/router'

export type LinkInput = {
	_type?: string,
	slug?: { current: string } | string,
	url?: string,
	selectedTab?: string,
	query?: string
	label?: string,
	locale?: string,
	docType?: string,
	reference?: {_ref: string},
}

export type LinkOutput = {
	href: string,
	label?: string,
	active?: boolean,
}

export const hrefWithLocale = (href = '', locale = defaultLocale) => {
	return `/${locale}${href === '/' ? '' : href}`
}

const rewrite = href => {
	const rewriteRule = rewrites.filter(rewrite => rewrite.destination === href)
	const result = rewriteRule[0]?.source || href
	return result.startsWith(`/${defaultLocale}`) ? result.replace(new RegExp(`/${defaultLocale}/?`), '/') : result
}

export const resolveLink = (link: LinkInput | string, router?: NextRouter) => {
	if (!link) return undefined

	const locale = router?.locale || defaultLocale

	if (typeof link === 'string') {
		if (link.indexOf('http') === -1) {
			const href = link.startsWith('#') ? link : rewrite(hrefWithLocale(link, locale))
			return {href, active: link === router?.pathname}
		} else {
			return {href: link, active: false}
		}
	} else {
		const _locale = link?.locale || locale

		if (link.selectedTab?.startsWith('external') && link.url) {
			return {href: link.url, label: link.label}
		} else if (link.selectedTab?.startsWith('internal') || link.slug || nextRoutes[link.docType || link._type]) {
			const {
				_type,
				docType,
				label,
				slug: sanitySlug,
				query = undefined,
			} = link

			let href = nextRoutes[docType || _type] || '/'
			const slug = typeof sanitySlug === 'string' ? sanitySlug : typeof sanitySlug?.current === 'string' ? sanitySlug?.current : null

			if (slug) {
				href = href.indexOf('/*') === -1 ? (href = `/${slug}`) : (href += slug)
				href = href.replace('/*', '/')
			}

			const active = href === router?.pathname

			href = rewrite(hrefWithLocale(href, _locale))

			if (query) {
				href += `?${query}`
			}

			return {href, label, active}
		}
	}

	return undefined
}

const useResolveLink: (link: LinkInput | string) => LinkOutput | undefined = link => {
	const router = useRouter()
	return resolveLink(link, router)
}

export default useResolveLink
