import useWindowEvent, {WindowEvent} from './useWindowEvent'

export interface PopStateEvent extends WindowEvent {
	state: any
}

const useWindowPopState = (
	cb: (e: PopStateEvent) => void,
	triggerInit = false,
	useEffectProps = [],
	debounceDelay = 0,
) => {
	useWindowEvent('popstate', cb, triggerInit, useEffectProps, debounceDelay)
}

export default useWindowPopState
