import {DependencyList, useCallback, useEffect, useRef} from 'react'
import {supportsPassiveEvents, supportsTouchEvents} from 'detect-it'

const useVirtualScroll = (callback, {
	useTouch = true,
	mouseMultiplier = 1,
	touchMultiplier = 2,
	firefoxMultiplier = 15,
	deps = [] as DependencyList,
} = {}) => {
	const posRef = useRef({x: 0, y: 0, deltaX: 0, deltaY: 0})
	const touchRef = useRef({x: 0, y: 0})
	const isFirefox = (typeof window === 'undefined') ? false : window.navigator.userAgent.indexOf('Firefox') > -1

	const update = useCallback(() => {
		posRef.current.x += posRef.current.deltaX
		posRef.current.y += posRef.current.deltaY

		callback(posRef.current)
	}, deps)

	const onWheel = useCallback((e) => {
		posRef.current.deltaX = e.wheelDeltaX || e.deltaX * -1
		posRef.current.deltaY = e.wheelDeltaY || e.deltaY * -1

		if (isFirefox && e.deltaMode === 1) {
			posRef.current.deltaX *= firefoxMultiplier
			posRef.current.deltaY *= firefoxMultiplier
		}

		posRef.current.deltaX *= mouseMultiplier
		posRef.current.deltaY *= mouseMultiplier

		update()
	}, deps)

	const onMouseWheel = useCallback((e) => {
		posRef.current.deltaX = e.wheelDeltaX ? e.wheelDeltaX : 0
		posRef.current.deltaY = e.wheelDeltaY ? e.wheelDeltaY : e.wheelDelta

		update()
	}, deps)

	const onTouchStart = useCallback((e) => {
		const t = e.targetTouches ? e.targetTouches[0] : e
		touchRef.current.x = t.pageX
		touchRef.current.y = t.pageY
	}, deps)

	const onTouchMove = useCallback((e) => {
		const t = e.targetTouches ? e.targetTouches[0] : e

		posRef.current.deltaX = (t.pageX - touchRef.current.x) * touchMultiplier
		posRef.current.deltaY = (t.pageY - touchRef.current.y) * touchMultiplier

		touchRef.current.x = t.pageX
		touchRef.current.y = t.pageY

		update()
	}, deps)

	useEffect(() => {
		const opts = supportsPassiveEvents ? false : {passive: true}

		window.addEventListener('wheel', onWheel, opts)
		window.addEventListener('mousewheel', onMouseWheel, opts)

		if (supportsTouchEvents && useTouch) {
			window.addEventListener('touchstart', onTouchStart, opts)
			window.addEventListener('touchmove', onTouchMove, opts)
		}

		return () => {
			window.removeEventListener('wheel', onWheel)
			window.removeEventListener('mousewheel', onMouseWheel)

			if (supportsTouchEvents && useTouch) {
				window.removeEventListener('touchstart', onTouchStart)
				window.removeEventListener('touchmove', onTouchMove)
			}
		}
	}, deps)
}

export default useVirtualScroll
