import useWindowEvent, {WindowEvent} from './useWindowEvent'

const useWindowScroll = (
	cb: (e: WindowEvent) => void,
	triggerInit = false,
	useEffectProps = [],
	debounceDelay = 0,
) => useWindowEvent('scroll', cb, triggerInit, useEffectProps, debounceDelay)

export default useWindowScroll
