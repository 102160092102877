import {create} from 'zustand'
import toast from 'react-hot-toast'

interface MinicartState {
	isOpened: boolean,
	setIsOpened: (flag: boolean) => void,
	toggleIsOpened: () => void,
}

const useMinicartStore = create<MinicartState>()(
	(set, get) => ({
		isOpened: false,
		setIsOpened: (flag) => {
			if (flag) toast.dismiss()
			set({isOpened: flag})
		},
		toggleIsOpened: () => {
			set({isOpened: !get().isOpened})
		},
	}),
)

export default useMinicartStore
