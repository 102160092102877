import {SanityImageType} from '@local/types'
import {ExtendedImageProps, Image} from '@local/ui/components'
import {ImageLoaderProps} from 'next/image'
import {devices} from '@local/config/dist/theme'
import {forwardRef} from 'react'
import {imageBuilder} from '@local/utils/sanity'

export interface SanityImageProps extends Omit<ExtendedImageProps, 'src' | 'alt'> {
	image: SanityImageType,
	alt?: string,
}

const desktopMockupWidth = devices.desktop.mockupWidth
const emptyImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

export const SanityImage = forwardRef(({
	image,
	width,
	height,
	fill,
	alt = '',
	style = {},
	...rest
}: SanityImageProps, ref) => {
	if (!image?.asset) {
		console.warn('undefined image')
		return null
	}

	const refSplit = image.asset._ref.split('-')
	const dimensions: number[] = refSplit[refSplit.length - 2].split('x').map(x => parseInt(x))
	const type = refSplit[refSplit.length - 1]
	const sanityImage = imageBuilder.image(image)
	const crop = sanityImage.options?.source?.crop
	const w = width || (crop && ((1 - crop.left - crop.right) * dimensions[0])) || dimensions[0]
	const h = height || (crop && ((1 - crop.top - crop.bottom) * dimensions[1])) || dimensions[1]
	const aspectRatio = w / h

	const sanityLoader = ({width, quality = 75}: ImageLoaderProps) => {
		if (width < 2) return emptyImage
		else return sanityImage.size(width | 0, width / aspectRatio | 0).fit('crop').quality(quality).auto('format').url()
	}

	return (
		<Image
			ref={ref}
			src={sanityImage.size(desktopMockupWidth, desktopMockupWidth / aspectRatio | 0).fit('crop').auto('format').url()}
			loader={sanityLoader}
			width={w}
			height={h}
			sizes={type === 'svg' ? '100vw' : undefined}
			alt={alt || image.alt || ''}
			fill={fill}
			style={style}
			{...rest} />
	)
})
