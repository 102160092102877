import debounce from 'lodash.debounce'
import {useEffect, DependencyList, RefObject, useCallback} from 'react'

const resizeObserver: { current: ResizeObserver | null } = {current: null}
const cbs: Array<{ ref: RefObject<HTMLElement>; callback }> = []

export const debouncedResizeObserver = (callback: ResizeObserverCallback, options: {delay?: number} = {
	delay: 100,
}) => {
	const accumulatedEntries = new Map<Element, ResizeObserverEntry>()
	const debouncedCallback = debounce((entries, observer) => {
		callback(entries, observer)
		accumulatedEntries.clear()
	}, options.delay, {leading: true, trailing: true})

	const ro = new ResizeObserver((entries) => {
		entries.forEach((entry) => accumulatedEntries.set(entry.target, entry))
		debouncedCallback(Array.from(accumulatedEntries.values()), ro)
	})

	return ro
}

const normalizeBoxSize = (contentBoxSize) => {
	return {
		width: contentBoxSize.inlineSize,
		height: contentBoxSize.blockSize,
	}
}

const useResizeObserver = (
	ref: RefObject<HTMLElement>,
	callback,
	deps: DependencyList = [],
) => {
	const resizeCallback = useCallback(entries => {
		cbs.forEach((cb, i) => {
			const ref = cb.ref.current
			entries.forEach(entry => {
				if (entry.target === ref) {
					cb.callback({
						...entry,
						contentRect: normalizeBoxSize(entry.contentBoxSize[0]) || entry.contentRect,
					}, i)
				}
			})
		})
	}, [])

	useEffect(() => {
		if (!ref.current) return
		const localRef = ref.current

		// create a single static resize observer
		if (resizeObserver.current === null) {
			resizeObserver.current = debouncedResizeObserver(resizeCallback)
		}

		// observe ref target
		resizeObserver.current.observe(localRef)
		cbs.push({ref, callback})

		return () => {
			// unobserve ref target
			if (resizeObserver.current !== null) {
				resizeObserver.current.unobserve(localRef)
				const index = cbs.findIndex(e => e.callback === callback)
				if (index !== -1) {
					cbs.splice(index, 1)
					if (cbs.length === 0) {
						resizeObserver.current.disconnect()
						resizeObserver.current = null
					}
				}
			}
		}
	}, deps)
}

export default useResizeObserver
