import {Theme} from '@local/types'

const themePairs = {
	white: 'black',
}

export const invertTheme = (theme: Theme, fallback?: string): Theme => {
	let inverted = fallback || theme

	Object.entries(themePairs).forEach((item) => {
		const idx = item.indexOf(theme)
		if (idx === 0) {
			inverted = item[1]
		}
		if (idx === 1) {
			inverted = item[0]
		}
	})

	return inverted as Theme
}
