import {clsx} from '@local/utils'

import SpinnerSVG from '@local/assets/svgs/spinner.svg'
import {Locale} from '@local/ui/components'

interface SpinnerProps {
	className?: string,
	svgClass?: string
}

export const Spinner = ({
	className,
	svgClass,
}: SpinnerProps) => {
	return (
		<div
			role='status'
			className={clsx(
				className,
				'pointer-events-none',
			)}>
			<SpinnerSVG
				className={clsx(
					'animate-spin',
					'fill-current',
					svgClass,
				)}
				aria-hidden='true' />
			<span className='sr-only'><Locale>Loading</Locale>...</span>
		</div>
	)
}
