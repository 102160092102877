import Link from 'next/link'
import {SanityImage} from '../sanityImage/SanityImage'
import {SanityImageType} from '@local/types'
import {AnimatedText} from '../animatedText/AnimatedText'
import {LogoDisplay} from '../logoDisplay/LogoDisplay'

export interface FooterProps {
	footerImage: SanityImageType
	footerTitle: string
	footerSubTitle: string
	footerLogoTitle: string
	footerLogoSubTitle: string
	footerCopyright: string
}

export const Footer = ({footerImage, footerTitle, footerSubTitle, footerLogoTitle, footerLogoSubTitle, footerCopyright}: FooterProps) => {
	return (
		<footer className='pt-120 lg:pt-420 font-serif text-[#454545]'>
			<div id='footer-wrapper' className='relative bg-[#bcdab7] rounded-t-3xl pt-65 lg:pt-180 lg:pb-85'>
				<picture className='absolute left-0 right-0 mx-auto pointer-events-none -top-72 lg:-top-275 w-310 h-110 lg:w-1180 lg:h-430'>
					<SanityImage image={footerImage} className='w-full' desktopWidth={1080} />
				</picture>
				<div id='footer-content' className='flex flex-col items-center content-center mb-100 gap-30'>
					<div id='footer-title' className='text-center leading-narrow span-w-6 lg:w-1010 text-26 lg:text-72'>
						<AnimatedText>
							{footerTitle}
						</AnimatedText>
					</div>
					<p id='footer-desc' className='text-14 span-w-6 lg:w-360 my-0 line-height-[132%] text-center text-[#363636]'>
						{footerSubTitle}
					</p>
					<Link href='/reachout' id='footer-cta2' className='group flex items-center content-center text-18 lg:text-26 rounded-full lg:py-36 lg:px-140 span-w-4-wider py-24 lg:w-fit h-fit lg:w-fit lg:h-fit text-center text-[#2e2e2e] bg-white'>
						<div id='footer-cta--inner' className='relative mx-auto overflow-hidden'>
							<div id='footer-cta--before' className='group-hover:translate-y-[-150%] transition-transform duration-500 ease-out-expo '>Reach Out</div>
							<div id='footer-cta--after' className='absolute group-hover:translate-y-0 transition-transform duration-500 ease-out-expo translate-y-[150%] bottom-0'>Reach Out</div>
						</div>
					</Link>
				</div>

				<div className='flex flex-col justify-between w-full my-0 lg:items-center lg:flex-row margin-px-1' id='footer-copy'>
					<div id='footer-logo' className='flex items-center text-24'>
						<div className='w-49 h-49'>
							<LogoDisplay />
						</div>
						<div className='flex flex-col lg:flex-row'>
							<div className='ml-14'>{footerLogoTitle}</div>
							<Link target='_blank' href='https://www.display.care' className='text-16 ml-14 text-[rgba(87,87,87,0.5)] ' rel='noreferrer'>{footerLogoSubTitle}</Link>
						</div>

					</div>
					<div id='footer-right' className='flex justify-between text-13 line-height-16 mt-40 mb-54 text-[rgba(87,87,87,0.5)]'>
						<div>{footerCopyright}</div>
					</div>
				</div>
			</div>
		</footer>
	)
}
