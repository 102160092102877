import {useRef} from 'react'
import useResizeObserver from './useResizeObserver'
import useScrollRatio from './useScrollRatio'

const useStickyScrollRatio = (containerRef, stickyRef, callback) => {
	const stickyHeight = useRef(0)

	useResizeObserver(stickyRef, ({contentRect}) => {
		stickyHeight.current = contentRect?.height || stickyRef.current?.offsetHeight
	})

	useScrollRatio(containerRef, (ratio, props) => {
		const {height} = props
		const stickyScrollDiff = height - stickyHeight.current

		callback(ratio, {...props, stickyScrollDiff, stickyHeight: stickyHeight.current})
	}, {
		offset: [[0, 0], [1, 1]],
		limitToBounds: [-0.5, 1.5],
	})
}

export default useStickyScrollRatio
