export const languages = [{
		id: 'en',
		title: 'English',
		isDefault: true
	},
	// {id: 'de', title: 'German'},
]

export const defaultLanguage = languages.find(l => l.isDefault)
export const otherLanguages = languages.filter(l => !l.isDefault)

export const locales = languages.map(l => l.id)
export const defaultLocale = defaultLanguage.id