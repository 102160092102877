import {useEffect} from 'react'
import {FORMATS, isFormatSupported} from '@local/utils/image'

export const supportedFormats = {current: ''}

export const getSupportedImageFormats = async (): Promise<string> => {
	if (!supportedFormats.current) {
		const formats = await Promise.all([isFormatSupported('avif', FORMATS.avif), isFormatSupported('webp', FORMATS.webp)])

		supportedFormats.current = formats
		// @ts-expect-error `'*'` isn't a specific image format to define inside ImageFormat type
		// but is a valid value to send as header.
			.concat('*')
			.filter(Boolean)
			.map(f => `image/${f}`)
			.join(',')
	}

	return supportedFormats.current
}

const useSupportedImageFormats = (callback: (formats: string) => void) => {
	useEffect(() => {
		const check = async () => {
			const formats = await getSupportedImageFormats()
			callback(formats)
		}

		check()
		callback(supportedFormats.current)
	}, [callback])
}

export default useSupportedImageFormats
