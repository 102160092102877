import {useRef, useState} from 'react'

import ScrollContext from './ScrollContext'

const ScrollContextProvider = ({children, lenis}) => {
	const [scrollDisabled, setScrollDisabled] = useState(false)

	const savedScroll = useRef<number>(0)

	const value = {
		lenisRef: lenis,
		scrollDisabled,
		disableScroll: value => {
			setScrollDisabled(value)

			if (value) {
				savedScroll.current = lenis.current.scroll
				lenis.current.stop()
				document.body.style.position = 'fixed'
				document.body.style.top = `-${savedScroll.current}px`
			} else {
				document.body.style.position = ''
				document.body.style.top = ''
				lenis.current.start()
				lenis.current.scrollTo(savedScroll.current, {immediate: true})
			}
		},
	}

	return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
}

export default ScrollContextProvider
