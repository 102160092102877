import {useLocaleStore} from '@local/stores'

const useLocale = () => {
	const locales = useLocaleStore(state => state.locales)
	return (value: string) => {
		return locales?.get(value) || value
	}
}

export default useLocale
