import {useRef, useCallback, RefObject} from 'react'
import {Device, DeviceEnum} from '@local/types'
import {offsetTop} from '@local/utils'

import useRaf from './useRaf'
import useResizeObserver from './useResizeObserver'
import useMatchDevice from './useMatchDevice'
import useScroll from './useScroll'
import useWindowSize from './useWindowSize'
import {precision} from '@local/utils/maths'

type UseScrollRatioCallBack = (
	ratio: number,
	options: {
		easing: number;
		scrollY: number;
		top: number;
		width: number;
		height: number;
		originalRatio: number;
		viewport: { width: number; height: number };
	}
) => void;

export type ScrollOffset = [[number, number], [number, number]];

type ScrollOptions = {
	mediaQueries?: DeviceEnum[];
	boundingClientRectRef?: RefObject<HTMLElement>;
	ease?: number;
	offset?: ScrollOffset;
	easingFunction?: (value: number) => number;
	useDocumentHeight?: boolean;
	limitToBounds?: [min: number, max: number];
	isSticky?: boolean
	proxyOffsetTopRef?: RefObject<HTMLElement>
};

const defaultOptions: ScrollOptions = {
	mediaQueries: Object.values(DeviceEnum),
	offset: [
		[0.5, 0.5],
		[1, 0],
	],
	easingFunction: value => value,
	// uses documentHeight as window.innerHeight,
	// similar to 100svh, it is a stable value
	// disable this in case your anim needs 100lvh
	useDocumentHeight: true,
	// run callback only within ratio bounds,
	// i.e, within [-1, 1] to reduce computations
	limitToBounds: [-1.05, 1.05],
	isSticky: false,
	// use instead of ref to calculate offsetTop
	proxyOffsetTopRef: undefined,

}

const useScrollRatio = (
	ref: RefObject<HTMLElement>,
	callback: UseScrollRatioCallBack,
	options: ScrollOptions = defaultOptions,
	deps: Array<any> = [],
) => {
	options = {...defaultOptions, ...options}

	const scrollY = useRef<number>(typeof window !== 'undefined' ? window.pageYOffset : 0)
	const enabled = useRef<boolean | null>(false)

	const viewport = useWindowSize(true)
	const ratio = useRef(0)
	const top = useRef(0)
	const width = useRef(0)
	const height = useRef(0)
	const immediate = useRef(false)

	const fromOffset = options.offset![0]
	const fromOffsetElement = fromOffset![0]
	const fromOffsetViewport = fromOffset![1]
	const toOffset = options.offset![1]
	const toOffsetElement = toOffset![0]
	const toOffsetViewport = toOffset![1]

	const device = useRef<Device | null>(null)

	useMatchDevice(detectedDevice => {
		device.current = detectedDevice
	})

	useResizeObserver(ref, e => {
		width.current = e.contentRect.width || (ref.current as HTMLElement).offsetWidth
		height.current = e.contentRect.height || (ref.current as HTMLElement).offsetHeight
		immediate.current = true
	})

	if (typeof window !== 'undefined') {
		useResizeObserver({current: document.body}, e => {
			enabled.current = device.current && options.mediaQueries!.indexOf(device.current as DeviceEnum) !== -1
			const targetRef = options.proxyOffsetTopRef || ref
			if (enabled.current && targetRef.current) {
				if (options.isSticky) {
					targetRef.current.style.position = 'static'
					top.current = offsetTop(targetRef.current)
					targetRef.current.style.position = 'sticky'
				} else {
					top.current = offsetTop(targetRef.current)
				}
			}
			immediate.current = true
		})
	}

	useScroll(({scroll}) => {
		scrollY.current = scroll || 0
	})

	const tick = useCallback(() => {
		const stableViewportHeight = options?.useDocumentHeight ? viewport.current.documentHeight : viewport.current.height

		if (enabled.current && ref.current && stableViewportHeight && height.current) {
			const easing = immediate.current ? 1 : options.ease || 1
			const fromY = fromOffsetElement * height.current - fromOffsetViewport * stableViewportHeight
			const toY = toOffsetElement * height.current - toOffsetViewport * stableViewportHeight
			const dY = toY - fromY

			ratio.current += ((scrollY.current! - (fromY + top.current)) / dY - ratio.current) * easing
			ratio.current = precision(ratio.current, 6)

			immediate.current = false

			// abort if we are outside bounds
			if (options?.limitToBounds && (ratio.current < options.limitToBounds[0] || ratio.current > options.limitToBounds[1])) return

			callback(options?.easingFunction ? options.easingFunction(ratio.current) : ratio.current, {
				easing,
				originalRatio: ratio.current,
				scrollY: scrollY.current,
				top: top.current,
				width: width.current,
				height: height.current,
				viewport: viewport.current,
			})
		}
	}, deps)

	useRaf(tick)
}

export default useScrollRatio
