import {useEffect} from 'react'

const mediaQueries = new Map()

const handleChange = (e: MediaQueryListEvent): void => {
	mediaQueries.get(e.media).callbacks.forEach(callback => {
		callback(e)
	})
}

const useMatchMedia = (queries: Array<string>, callback: (e: MediaQueryListEvent | MediaQueryList) => string | undefined) => {
	useEffect(() => {
		queries.forEach((q: string) => {
			const mappedMq = mediaQueries.get(q)
			if (!mappedMq) {
				const mq = window.matchMedia(q)
				mq.addEventListener('change', handleChange)
				if (mq.matches) callback(mq)
				mediaQueries.set(q, {mq, callbacks: [callback]})
			} else {
				if (mappedMq.mq.matches) callback(mappedMq.mq)
				mediaQueries.set(q, {...mappedMq, callbacks: [...mappedMq.callbacks, callback]})
			}
		})

		return () => {
			queries.forEach((q: string) => {
				const mappedMq = mediaQueries.get(q)
				if (mappedMq) {
					mappedMq.callbacks.splice(mappedMq.callbacks.indexOf(callback), 1)
					if (!mappedMq.callbacks.length) {
						mappedMq.mq.removeEventListener('change', handleChange)
						mediaQueries.delete(q)
					}
				}
			})
		}
	}, [])
}

export default useMatchMedia
