// @ts-nocheck
import {FooterTheme, LogoVariantType, Theme} from '@local/types'

import {create} from 'zustand'

interface AppState {
	scrollbarWidth: number,
	scrollbarEnabled: boolean,

	headerHeight: number
	footerHeight: number
	shopMode: string

	headerTheme: Theme
	footerTheme: FooterTheme
	logoVariant: LogoVariantType

	setScrollbarEnabled: () => void,
}

const useAppStore = create<AppState>(set => ({
	scrollbarWidth: 0,
	scrollbarEnabled: true,

	headerHeight: 0,
	footerHeight: 0,

	headerTheme: null,
	footerTheme: null,
	logoVariant: 'default',
	shopMode: 'all',

	setScrollbarEnabled: value => set(() => ({
		scrollbarEnabled: value,
	})),
}))

export default useAppStore
