import {createClient} from '@sanity/client'
import {sanity} from '@local/config'
import {sleep} from './sleep'

const client = createClient(sanity.auth)

/*
This doesn't takes care of the initial creation of the Sanity document
just do it youself:
// const transaction = client.transaction()
// transaction.create({
// 	_id: docId, // '_buffer.{key}'
// 	_type: 'bufferedRequests',
// 	payloads: [],
// })
// await transaction.commit()
*/

export const bufferedRequests = async (props: {
	payload: object,
	res,
	key: string,
	handlePayloads: (payloads: object[], res) => void | Promise<void>,
	wait?: number,
}) => {
	const {
		payload,
		res,
		key,
		handlePayloads,
		wait = 1000, // shouldn't exceed timeout limit - exec duration
	} = props

	const docId = `_buffer.${key}`
	const sanityRecord = {
		_type: 'object',
		json: JSON.stringify(payload),
		date: new Date().toISOString(),
	}

	// add to payloads
	const insertTransaction = client.transaction()
	insertTransaction.patch(docId, {
		insert: {
			after: 'payloads[-1]',
			items: [sanityRecord],
		},
	})
	const insertResult = await insertTransaction.commit<{payloads:(typeof sanityRecord)[]}>({returnDocuments: true})
	const pushedSanityDocument = insertResult[0]

	await sleep(wait)

	const flushTransaction = client.transaction()
	// try to flush
	flushTransaction.patch(docId, {
		ifRevisionID: pushedSanityDocument._rev,
		set: {
			payloads: [],
		},
	})
	try {
		await flushTransaction.commit()
		// flush succeeded, handle payloads
		const result = await handlePayloads(
			pushedSanityDocument.payloads
				?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
				?.map(p => JSON.parse(p.json)),
			res,
		)
		return result
	} catch (e) {
		if (e.statusCode === 409) {
			// patch error: Conflict
			// doc was patched by another call before flush
			// Shopify Sanity Connect expects 200 only: "Response status code was "202", expected "200""
			// return res.status(202).json({ok: true, message: 'Accepted'})
			return res.status(200).json({ok: true, message: 'Accepted'})
		} else {
			console.log('packages/utils/bufferedRequests.ts | error', e)
			return res.status(500).json({ok: false, error: e})
		}
	}
}
