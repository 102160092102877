import Layout from 'layouts/Layout'
import App from 'next/app'

import {useLocaleStore} from '@local/stores'
import type {AppContext, AppInitialProps, AppProps} from 'next/app'

import '@local/fonts/fonts.css'
import '@local/styles/global.css'
import {Analytics} from '@vercel/analytics/react'
import {useEffect, useState} from 'react'
import {LottieAnimation} from '@local/ui/components/lottieAnimation/LottieAnimation'
import {Slide, ToastContainer} from 'react-toastify'

interface AddedProps {
	statusCode?: number,
	locales?: any[],
	settings?: any,
	error?: Error,
}

type MyAppInitalProps = AppInitialProps & AddedProps
type MyAppProps = AppProps & AddedProps

const MyApp = ({Component, pageProps, settings, locales}: MyAppProps) => {
	const setLocales = useLocaleStore(state => state.setLocales)
	if (locales) setLocales(locales)
	const bgColors = ['#8486Ec', '#bcdab7', '#FF9776', '#00BEA9']
	const [bgColor, setBgColor] = useState('')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setBgColor(bgColors[Math.floor(Math.random() * bgColors.length)])
		setTimeout(() => {
			setLoading(true)
		}, 1000)
	}, [])
	return (
		<>
			{!loading
				? (
					<LottieAnimation />
				)
				: (
					<Layout bgColor={bgColor} pageProps={pageProps} settings={settings}>
						<Component bgColor={bgColor} {...pageProps} />
					</Layout>
				)}
			<ToastContainer
				position='bottom-center'
				autoClose={500}
				hideProgressBar
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				transition={Slide}
				theme='light' />
			<Analytics />
		</>
	)
}

MyApp.getInitialProps = async (appContext: AppContext) => {
	// fetch this only on server-side
	if (typeof window !== 'undefined') return null

	const {setLocale, getGlobalConfig} = await import('lib/sanity')

	const {router, ctx} = appContext
	const {locale, defaultLocale, locales} = router

	setLocale(locale, defaultLocale, locales)

	const appProps: MyAppInitalProps = await App.getInitialProps(appContext)

	appProps.statusCode = ctx.res?.statusCode
	try {
		const config: any = await getGlobalConfig()
		appProps.settings = config.settings
		appProps.locales = config.locales
	} catch (error) {
		return {...appProps, error}
	}

	return {...appProps}
}

export default MyApp
