import {useLocale} from '@local/hooks'

interface LocaleProps {
	children: string
}

export const Locale = ({children}: LocaleProps) => {
	const locale = useLocale()
	console.log('locale', locale)
	return (
		<>
			{locale(children)}
		</>
	)
}
