export interface InputErrors {
	badInput?: string,
	customError?: string,
	patternMismatch?: string,
	rangeOverflow?: string,
	rangeUnderflow?: string,
	stepMismatch?: string,
	tooLong?: string,
	tooShort?: string,
	typeMismatch?: string,
	valid?: string,
	valueMissing?: string,
	exampleValidInput?: string
}

const defaultErrors = {
	badInput: 'is a bad input',
	patternMismatch: 'format incorrect',
	rangeOverflow: 'is too high',
	rangeUnderflow: 'is too low',
	stepMismatch: 'does not match the step',
	tooLong: 'too long',
	tooShort: 'too short',
	typeMismatch: 'format incorrect',
	valueMissing: 'is required',
}

export const makeErrors = (_options: InputErrors = {}) => {
	const options = {...defaultErrors, ..._options}

	return (field: HTMLInputElement, _name = '') => {
		const {placeholder, name: defaultName, validity, minLength, maxLength} = field

		const name = placeholder?.replace('*', '') || _name || defaultName

		// find the first error
		const errorKey = Object.keys(options).find(key => validity[key] ? key : false)

		if (errorKey && options[errorKey]) {
			if (errorKey === 'patternMismatch' && options.exampleValidInput) {
				return `${name} ${options[errorKey]} ${options.exampleValidInput}`
			}

			if (errorKey === 'tooShort' && minLength) {
				return `${name} should be atleast ${minLength} characters`
			}

			if (errorKey === 'tooLong' && maxLength) {
				return `${name} cannot be more than ${maxLength} characters`
			}

			return `${name} ${options[errorKey]}`
		}
	}
}
