import React, {useCallback, useRef} from 'react'

/**
 * Returns an object where the first item is the [ref](https://reactjs.org/docs/hooks-reference.html#useref) to a
 * callback function and the second one is setter for that function.
 *
 * Although it function looks quite similar to the [useState](https://reactjs.org/docs/hooks-reference.html#usestate),
 * hook, in this case the setter just makes sure the given callback is indeed a new function.<br /><br />
 * **Setting a callback ref does not force your component to re-render.**<br /><br />
 *
 * `useCallbackRef` is useful when abstracting other hooks to possibly implement handlers setters.
 */
const useCallbackRef = (initialValue: React.Ref<any>) => {
	const callbackRef = useRef(initialValue)

	const setCallbackRef = useCallback(
		(nextCallback: () => void, invokeImmediately = false) => {
			if (nextCallback !== callbackRef.current && typeof nextCallback === 'function') {
				callbackRef.current = nextCallback
				if (invokeImmediately) callbackRef.current()
			}
		},
		[callbackRef.current],
	)

	return [callbackRef, setCallbackRef]
}

export default useCallbackRef
