import {useEffect, useRef} from 'react'
import useWindowResize from './useWindowResize'

const useResize = (ref, callback, options: any = null, effectProps: any[] = []) => {
	const cb = useRef(callback)
	const intersectionObserver = useRef<IntersectionObserver | null>(null)

	useEffect(() => {
		cb.current = callback
	}, [callback])

	useWindowResize(() => {
		if (ref.current && intersectionObserver.current) {
			intersectionObserver.current.observe(ref.current)
		}
	})

	useEffect(() => {
		if (!window.IntersectionObserver) return

		intersectionObserver.current = new IntersectionObserver(entries => {
			const entry = entries[0]
			if (entry) {
				cb.current(entry)
				intersectionObserver.current!.unobserve(entry.target)
			}
		}, options || {})

		intersectionObserver.current.observe(ref.current)

		return () => {
			if (intersectionObserver.current) {
				intersectionObserver.current.disconnect()
			}
		}
	}, [ref, options, ...effectProps])
}

export default useResize
