type WorkerType = {
	current: Worker | null;
};

type ImageFormat = 'avif' | 'webp' | 'jpg' | 'jpeg' | 'png' | 'apng';

export const FORMATS = {
	avif: 'AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAAEcbWV0YQAAAAAAAABIaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGNhdmlmIC0gaHR0cHM6Ly9naXRodWIuY29tL2xpbmstdS9jYXZpZgAAAAAeaWxvYwAAAAAEQAABAAEAAAAAAUQAAQAAABcAAAAqaWluZgEAAAAAAAABAAAAGmluZmUCAAAAAAEAAGF2MDFJbWFnZQAAAAAOcGl0bQAAAAAAAQAAAHJpcHJwAAAAUmlwY28AAAAQcGFzcAAAAAEAAAABAAAAFGlzcGUAAAAAAAAAAQAAAAEAAAAQcGl4aQAAAAADCAgIAAAAFmF2MUOBAAwACggYAAYICGgIIAAAABhpcG1hAAAAAAAAAAEAAQUBAoMDhAAAAB9tZGF0CggYAAYICGgIIBoFHiAAAEQiBACwDoA=',
	webp: 'UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=',
}

const worker: WorkerType = {current: null}
const createWorker = f => {
	return new Worker(URL.createObjectURL(new Blob([`(${f})()`])))
}

export const isFormatSupported = (format: ImageFormat, data: string) =>
	new Promise<ImageFormat | null>(resolve => {
		const image = new Image()

		image.onload = () => resolve(format)
		// `resolve` with null instead of reject to prevent the use of `.catch()`
		image.onerror = () => resolve(null)

		image.src = `data:image/${format};base64,${data}`
	})

const fetchBitmap = ({src, formats, quality, mode, priority}) => {
	const _src = src.indexOf('http') === -1 ? (self ? self.location.origin : window.location.origin) + src : src

	const accept = `${formats};q=${quality * 0.01}`

	return fetch(_src, {headers: {accept}, mode, priority})
		.then(response => response.blob())
		.then(blob => createImageBitmap(blob))
		.catch(console.warn.bind(null, 'loadImageWithWorker error'))
}

type LoadProps = { src: string; formats?: string; quality?: number; mode?: RequestMode; priority?: string; withBitmap?: boolean };

export const loadImageWithImageTag = ({
	src,
	formats,
	quality,
	mode,
	priority = 'low',
	signal,
	withBitmap,
}: LoadProps & { signal?: AbortSignal }) => {
	if (window.createImageBitmap && withBitmap) {
		return fetchBitmap({src, formats, quality, mode, priority})
	}

	return new Promise<HTMLImageElement>((resolve, reject) => {
		signal?.addEventListener('abort', () => {
			img.src = ''
			reject(new DOMException('Aborted'))
		})

		const img = new Image()
		img.crossOrigin = ''
		img.fetchPriority = priority
		img.src = src
		img.onload = () => {
			resolve(img)
		}
		img.onerror = () => {
			reject(img)
		}
	})
}

export const loadImageWithWorker = (options: LoadProps) => {
	if (window.createImageBitmap && options.withBitmap) {
		if (!worker.current) {
			worker.current = createWorker(() => {
				self.addEventListener('message', event => {
					const {src, formats = 'image/*,*/*', quality = 75, mode = 'same-origin', priority} = event.data

					const _src = src.indexOf('http') === -1 ? self.location.origin + src : src

					const accept = `${formats};q=${quality * 0.01}`

					fetch(_src, {headers: {accept}, mode, priority})
						.then(response => response.blob())
						.then(blob => createImageBitmap(blob))
						.then(bitmap => self.postMessage({src, bitmap}, [bitmap]))
						.catch(console.warn.bind(null, 'loadImageWithWorker error'))
				})
			})
		}

		return new Promise<ImageBitmap>((resolve, reject) => {
			const handler = (e: MessageEvent) => {
				if (e.data.src === options.src) {
					if (worker.current) {
						worker.current.removeEventListener('message', handler)
					}
					if (e.data.error) {
						reject(e.data.error)
					}
					resolve(e.data.bitmap)
				}
			}

			if (worker.current) {
				worker.current.addEventListener('message', handler)
				worker.current.postMessage(options)
			}
		})
	}

	// fallback to image tag loading
	return loadImageWithImageTag(options)
}
