import {Locale} from '@local/types'
import {create} from 'zustand'

type MapType = Map<string, string> | null
interface UseLocaleStoreProps {
	locales: MapType,
	setLocales: (locales: Array<Locale>) => void,
	getLocale: (key: string) => string,
}

const localesToMap = (locales: Array<Locale>, map: MapType = null) => {
	if (!map) map = new Map<string, string>()
	locales?.forEach(item => map!.set(item.key, item.value))
	return map
}

const useLocaleStore = create<UseLocaleStoreProps>((set, get) => ({
	locales: null,
	setLocales: (locales) => {
		set(curr => ({
			locales: localesToMap(locales, curr.locales),
		}))
	},
	getLocale: (key) => {
		return get().locales?.get(key) || key
	},
}))

export default useLocaleStore
