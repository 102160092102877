import Head from 'next/head'

export const AccessibeScript = () => (
	<Head>
		<script
			dangerouslySetInnerHTML={{
				__html: `
          (function(){
            var s = document.createElement('script');
            var h = document.querySelector('head') || document.body;
            s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
            s.async = true;
            s.onload = function(){
              acsbJS.init({
                statementLink : '',
                footerHtml : '',
                hideMobile : false,
                hideTrigger : false,
                disableBgProcess : false,
                language : 'en',
                position : 'left',
                leadColor : '#8468ec',
                triggerColor : '#8486ec',
                triggerRadius : '50%',
                triggerPositionX : 'right',
                triggerPositionY : 'bottom',
                triggerIcon : 'wheels2',
                triggerSize : 'medium',
                triggerOffsetX : 20,
                triggerOffsetY : 20,
                mobile : {
                  triggerSize : 'small',
                  triggerPositionX : 'right',
                  triggerPositionY : 'bottom',
                  triggerOffsetX : 10,
                  triggerOffsetY : 10,
                  triggerRadius : '50%'
                }
              });
            };
            h.appendChild(s);
          })();
        `,
			}} />
	</Head>
)
