import Lottie, {LottieRef} from 'lottie-react'
import {useEffect, useRef} from 'react'
import animationData from './allshapes-preloader.json'
import {motion, AnimatePresence} from 'framer-motion'

export const LottieAnimation = () => {
	const lottieRef: LottieRef = useRef(null)

	useEffect(() => {
		if (lottieRef.current) {
			const lottie = lottieRef.current
			lottie.setSpeed(2)
			lottie.goToAndPlay(1000)
		}
	}, [])

	return (
		<AnimatePresence mode='wait'>
			<motion.div
				initial={{opacity: 0, y: 20}}
				animate={{opacity: 1, y: 0}}
				exit={{opacity: 0, y: -20}}
				transition={{duration: 0.2}}
				className='w-screen h-screen grid lg:grid-cols-3 grid-rows-3 lg:bg-[#FCF9ED] bg-[#CAD3FB]'>
				<div className='lg:col-start-2 row-start-2 flex justify-center items-center'>
					<Lottie
						className='w-full h-full'
						autoPlay
						loop
						lottieRef={lottieRef}
						animationData={animationData} />
				</div>
			</motion.div>
		</AnimatePresence>
	)
}
