import {useState, useEffect, useCallback} from 'react'

type UseTouchEventsHook = () => boolean

// No touch support by default
const store = {touchevents: false}

/**
 * Safest way to test touch events support: wait until the user *touches* the screen
 * @returns {Boolean} True if touch events are supported, false otherwise
 */
const useTouchEvents: UseTouchEventsHook = () => {
	const [touchevents, setTouchevents] = useState<boolean>(store.touchevents)

	const onFirstTouch = useCallback(() => {
		document.documentElement.classList.add('touchevents')
		document.documentElement.classList.remove('no-touchevents')
		window.removeEventListener('touchstart', onFirstTouch)

		store.touchevents = true
		setTouchevents(true)
	}, [])

	useEffect(() => {
		// If touch events have already been tested bypass test
		if (store.touchevents) return

		// Test touch events: check if the user *touches* the screen
		document.documentElement.classList.add('no-touchevents')
		window.addEventListener('touchstart', onFirstTouch)

		return () => {
			window.removeEventListener('touchstart', onFirstTouch)
		}
	}, [])

	return touchevents
}

export default useTouchEvents
