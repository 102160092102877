import {Suspense, useEffect} from 'react'
import {primaryInput} from 'detect-it'
import {useWindowResize} from '@numbered/hooks'

import type {ReactNode} from 'react'
import {useScrollStore} from '@local/stores'
import {useRaf, useGridControls} from '@local/hooks'
import {ScrollContextProvider} from '@local/contexts'
import {Toaster} from 'react-hot-toast'
import {useRouter} from 'next/router'
import {Head, NavBar, Footer} from '@local/ui/components'
import {AccessibeScript} from '@local/ui/components/accessibeScript/AccessibeScript'

export interface LayoutProps {
	children: ReactNode
	pageProps: any
	settings?: any
	bgColor: any
}

const Layout = ({
	children,
	pageProps,
	settings,
	bgColor,
}: LayoutProps) => {
	const {seo, header, footer} = settings || {}
	const [lenis] = useScrollStore(state => [state.lenis])
	const router = useRouter()

	useWindowResize((e: UIEvent & { innerWidth: number, offsetWidth: number }) => {
		if (primaryInput !== 'touch') {
			const sbw = e.innerWidth - e.offsetWidth
			document.documentElement.style.setProperty('--sbw', `${sbw}px`)
		}
		document.documentElement.style.setProperty('--screen-width', `${e.innerWidth}px`)
	}, true)

	useEffect(() => {
		// reset scroll on route change
		const handleRouteChange = () => {
			if (process.env.NODE_ENV === 'development') return
			window.scrollTo({top: 0, behavior: 'auto'})
			lenis?.scrollTo?.(0, {immediate: true})
		}

		// update scroll on hash change
		const onHashChangeStart = (url) => {
			url = '#' + url.split('#').pop()
			if (url === '#') {
				// scroll to top
				lenis.scrollTo(0, {immediate: true})
			} else {
				// scroll to anchor
				lenis.scrollTo(url, {immediate: true})
			}
		}

		router.events.on('routeChangeComplete', handleRouteChange)
		router.events.on('hashChangeStart', onHashChangeStart)

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
			router.events.off('hashChangeStart', onHashChangeStart)
		}
	}, [lenis])

	useEffect(() => {
		window.history.scrollRestoration = 'manual'
	}, [])

	useRaf((time: number) => lenis?.raf(time), true)
	useGridControls()

	return (
		<>
			<ScrollContextProvider lenis={lenis}>

				{seo && <Head {...seo} title={seo.title?.replace('{siteTitle}', seo.siteTitle).replace('{pageTitle}', pageProps?.seo?.title || pageProps?.title || '')} />}
				<Suspense fallback={null}>
					{header && <NavBar bgColor={bgColor} {...header} />}
				</Suspense>
				<Suspense fallback={null}>
					<Toaster
						position='top-right'
						containerClassName='!top-[4rem] lg:!top-[3.5rem] !right-[var(--margin)] !left-[var(--margin)]'
						toastOptions={{
							duration: 5000,
						}} />
				</Suspense>
				<Suspense fallback={null}>
					<>
						<main key={pageProps._id} className='sm-max:min-w-full relative font-serif text-[#454545] select-none'>{children}</main>
						{footer && <Footer {...footer} />}
						{pageProps?.preview && (
							<a href='/api/exit-preview' className='fixed text-white rounded p-30 bg-black-full bottom-20 right-20 w-fit'>
								Exit preview
							</a>
						)}
						<AccessibeScript />
					</>
				</Suspense>

			</ScrollContextProvider>
		</>
	)
}

export default Layout
