import Link from 'next/link'
import Logo from '@local/assets/svgs/logo/base.svg'
import {useState, type ReactNode, useRef} from 'react'
import {BurgerMenu} from '../burgerMenu/BurgerMenu'
import {resolveLink} from '@local/hooks/useResolveLink'
import {Button} from '../button/Button'
import {AnimatePresence} from 'framer-motion'
import {clsx} from '@local/utils'

export interface NavBarProps {
	children?: ReactNode
	className?: string
}

export const NavBar = ({headerLogoTitle, links, bgColor}) => {
	const ct = useRef(0)
	const [isActive, setIsActive] = useState(false)

	return (
		<nav className='animate-hero-nav absolute z-40 flex justify-between w-full bg-transparent margin-px-1 pt-36 h-85 font-serif text-[#454545] select-none'>
			<Link href='/' className='flex items-center'>
				<div className='flex items-center cursor-pointer'>
					<div className='mr-16 w-65 h-60'>
						<Logo parent='NavBar' />
					</div>
					<div className='text-22'>{headerLogoTitle}</div>
				</div>
			</Link>
			<ul id='nav-list' className='lg:flex items-center text-sm md:hidden md-max:hidden'>
				{
					links.map((link, idx) => { return (<li key={idx} className='mr-48 text-14 last:mr-0 cursor-pointer'><Button label={resolveLink(link)?.label} href={resolveLink(link)?.href} hover='rotate'>{resolveLink(link)?.label}</Button></li>) })
				}
			</ul>
			<Link href='/reachout' id='nav-cta' className={clsx('group md:hidden lg:flex items-center content-center text-white cursor-pointer px-40 text-16 rounded-full md-max:hidden', `bg-[${bgColor}]`)}>
				<div className='relative overflow-hidden'>
					<div className='group-hover:translate-y-[-150%] transition-transform duration-500 ease-out-expo '>Submit a Story</div>
					<div className='absolute group-hover:translate-y-0 transition-transform duration-500 ease-out-expo translate-y-[150%] bottom-0'>Submit a Story</div>
				</div>
			</Link>
			<div
				onClick={() => {
					if (!isActive) {
						ct.current += 1
					}
					setIsActive(!isActive)
				}}
				className='z-40 w-60 h-60 m-26 absolute right-0 top-0 rounded-full bg-[#8486Ec] cursor-pointer flex items-center content-center lg:hidden'>
				<div className={`burger ${isActive ? 'burgerActive' : ''}`} />
			</div>
			<AnimatePresence>
				{isActive && <BurgerMenu links={links} setIsActive={setIsActive} count={ct} />}
			</AnimatePresence>
		</nav>
	)
}
