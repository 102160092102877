/* eslint-disable no-mixed-spaces-and-tabs */
import {forwardRef, useState} from 'react'
import {clsx} from '@local/utils'

import NextImage from 'next/image'
import type {ImageProps} from 'next/image'
import {screens, devices} from '@local/config/dist/theme'

const mobileMockupWidth = devices.mobile.mockupWidth
const desktopMockupWidth = devices.desktop.mockupWidth
const mobileMaxWidth = screens[devices.tablet.screen]

export interface ExtendedImageProps extends ImageProps {
	mobileWidth?: number
	desktopWidth?: number
	mobileOnly?: boolean
	desktopOnly?: boolean
	animated?: boolean
}

const ImageWithoutRef: React.FC<ExtendedImageProps> = ({
	src,
	width,
	height,
	mobileWidth = devices.mobile.mockupWidth,
	desktopWidth = devices.desktop.mockupWidth,
	alt = '',
	fill,
	loader,
	className,
	style = {},
	mobileOnly = false,
	desktopOnly = false,
	priority = false,
	unoptimized,
	sizes,
	animated = true,
	onLoadingComplete,
	...args
}, ref) => {
	const [isLoaded, setIsLoaded] = useState(false)

	const _sizes =
        sizes ||
        `${!mobileOnly
        	? `(min-width: ${mobileMaxWidth}px) ${Math.round((desktopWidth * 100) / desktopMockupWidth)}vw`
        	: `(max-width: ${mobileMaxWidth - 1}px) ${Math.round((mobileWidth * 100) / mobileMockupWidth)}vw`
        }${!desktopOnly && !mobileOnly
        	? `,${Math.round((mobileWidth * 100) / mobileMockupWidth)}vw`
        	: ',0vw'
        }`

	const handleLoad = img => {
		if (onLoadingComplete) onLoadingComplete(img)
		setIsLoaded(true)
	}

	return (
		<NextImage
			ref={ref}
			unoptimized={
				unoptimized !== undefined
					? unoptimized
					: process.env.XSTORYBOOK_EXAMPLE_APP !== undefined
			}
			src={src}
			alt={alt}
			fill={fill}
			sizes={_sizes}
			width={fill ? undefined : width}
			height={fill ? undefined : height}
			loader={loader}
			priority={priority}
			style={style}
			className={clsx(
				mobileOnly && 'lg:hidden',
				desktopOnly && 'lg-max:hidden',
				animated && 'transition-opacity duration-500 ease-out-quart',
				animated && ((priority || isLoaded) ? 'opacity-100' : 'opacity-0'),
				className,
			)}
			onLoadingComplete={handleLoad}
			{...args} />
	)
}

export const Image = forwardRef(ImageWithoutRef)
