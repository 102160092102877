import {type ReactNode} from 'react'
import {clsx} from '@local/utils'
import {Button} from '../button/Button'
import {resolveLink} from '@local/hooks/useResolveLink'
import {motion} from 'framer-motion'
import {menuSlide} from '../navBar/anim'
import {Curve} from '../curve/Curve'

export interface BurgerMenuProps {
	children: ReactNode
	className: string
}

const bgColors = ['#bcdab7', '#FF9776', '#00BEA9']

export const BurgerMenu = ({links, setIsActive, count}) => {
	const bgColor = bgColors[count.current % 3]
	return (
		<motion.div
			variants={menuSlide}
			initial='initial'
			animate='enter'
			exit='exit'
			className={clsx('z-30 h-screen w-screen grid fixed right-0 top-0 text-white place-items-center noscroll', `bg-[${bgColor}]`)}>
			<div>
				<div className='flex flex-col gap-12 text-53'>
					<Button
						label='Home'
						href={resolveLink('/')?.href}
						onClick={() => { setIsActive(false) }}
						className='burger-menu--link'>
						Home
					</Button>
					{links.map((link, idx) => {
						return (
							<Button
								key={idx}
								label={resolveLink(link)?.label}
								href={resolveLink(link)?.href}
								onClick={() => { setIsActive(false) }}
								className='burger-menu--link'>
								{resolveLink(link)?.label}
							</Button>
						)
					})}
				</div>
			</div>
			<Curve bgColor={bgColor} />
		</motion.div>
	)
}
