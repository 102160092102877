import {createContext} from 'react'

interface ScrollContextProps {
	lenisRef?;
	scrollDisabled: boolean;
	disableScroll: (value: boolean) => void;
}

const ScrollContext = createContext<ScrollContextProps>({
	lenisRef: null,
	scrollDisabled: false,
	disableScroll: null,
})

export default ScrollContext
